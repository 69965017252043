interface Window{
    zidxBase:ZIDXBase;
}
class ZIDXSiteHome implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxSiteHomeContainer";
    }
    getMatchURL(){
        return "/";
    }
    render(){
        // console.log("site home");
        window.zidxBase.init(true, false, true, true)
    }
}